import React, { useEffect, useState } from "react";

import CustomText from "./CustomText";
import Container from "./Container";

import logo from "../assets/logo.png";
import playStoreBadge from "../assets/playstore.png";
import appStoreBadge from "../assets/appstore.png";
import zIndex from "@mui/material/styles/zIndex";

const yc = require("../assets/yc.png");

export default function NavBar() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    /* Inside of a "useEffect" hook add an event listener that updates
        the "width" state variable when the window size changes */
    window.addEventListener("resize", () => setWidth(window.innerWidth));

    /* passing an empty array as the dependencies of the effect will cause this
        effect to only run when the component mounts, and not each time it updates.
        We only want the listener to be added once */
  }, []);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      padding: "1em 2em",
      justifyContent: "space-between",
      backgroundColor: "white",
      zIndex: 500,
      width: "100vw",
      boxSizing: "border-box",
    },
    downloadImg: {
      height: "3em",
    },
  };
  return (
    <Container style={styles.container}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flex: 1,
        }}
      >
        <img src={logo} style={{ width: 50, height: 50 }} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <CustomText value={"orca"} size={2} bold font />
        </div>
      </div>
      <a href="https://www.ycombinator.com/">
        <div
          style={{
            display: "flex",
            flexDirection: window.innerWidth < 500 ? "column" : "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <CustomText
            value={"Backed by "}
            size={1}
            bold={true}
            font={true}
            block={null}
            center={null}
            children={null}
            color={"#000"}
            style={null}
          />
          <img src={yc} style={{ width: "15vh" }} />
        </div>
      </a>
    </Container>
  );
}
