import React, { useEffect, useRef, useState } from "react";
import Animation from "../components/Animation";
import NavBar from "../components/NavBar";
import Texts from "../components/Texts";
import CardSecurityBubbles from "../components/CardSecurityBubbles";
import Parallax from "../components/Parallax";
import Container from "../components/Container";
import CustomText from "../components/CustomText";
import Home from "./Home";

function throttle(fn: any, wait: number) {
  var time = Date.now();
  return function () {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
}

const App = () => {
  return (
    <>
      <div style={{ height: "100vh" }}>
        <div style={{ height: "5420px" }}>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              position: "fixed",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
            className="container"
          >
            <div style={{ maxWidth: "200em", zIndex: 100 }}>
              <NavBar />
            </div>
            <div style={{ maxWidth: "200em" }}>
              <Animation />
              <Parallax />
            </div>
            <Texts />
            <CardSecurityBubbles />
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
