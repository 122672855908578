import React from "react";

// font = carter one
export default function CustomText({
  block,
  bold,
  center,
  size,
  font,
  color,
  value,
  style,
  children,
}) {
  const styles = {
    textStyle: {
      margin: 0,
      fontSize: size ? `${size}em` : "1.25em",
      fontWeight: bold ? "bold" : "normal",
      fontFamily: font ? "Carter One" : "Open Sans",
      textAlign: center ? "center" : "left",
      color: color ? color : "#000",
    },
  };

  return block ? (
    <p style={{ ...styles.textStyle, ...style }}>{value}</p>
  ) : (
    <span style={{ ...styles.textStyle, ...style }}>
      {value}
      {children}
    </span>
  );
}
