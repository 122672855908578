//@ts-nocheck
import React, { useEffect, useRef, useCallback, useState } from "react";

// input range = [0, .1]
// output range = [100, 20]
const STARTING_INDEX = 22;
const NUM_PICTURES = 104;

const images = [];
const img = new Image();

const currentFrame = (index) => {
  return `${process.env.PUBLIC_URL}/imageSequence/${(STARTING_INDEX + index)
    .toString()
    .padStart(4, "0")}.jpg`;
};

const preloadImages = () => {
  for (let i = 1; i < NUM_PICTURES; i++) {
    images[i] = new Image();
    images[i].src = currentFrame(i);
  }
};

const Animation = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    /* Inside of a "useEffect" hook add an event listener that updates
        the "width" state variable when the window size changes */
    window.addEventListener("resize", () => setWidth(window.innerWidth));

    /* passing an empty array as the dependencies of the effect will cause this
        effect to only run when the component mounts, and not each time it updates.
        We only want the listener to be added once */
  }, []);

  const [top, setTop] = useState(50); // starting from 50%, go to -50%
  const [indicator, setIndicator] = useState(true);

  let context = useRef(null);
  const videoRef = useCallback((ref) => {
    console.log(ref);
    if (ref) {
      ref.width = 1920;
      ref.height = 1080;
      img.src = currentFrame(0);
      context.current = ref.getContext("2d");
      img.onload = function () {
        context.current?.drawImage(img, 0, 0);
        console.log("context", ref.getContext("2d"));
      };
    }
  }, []);

  useEffect(() => {
    preloadImages();
  }, [context, videoRef]);

  // const videoStyle = {
  //   width: `calc((100vh - 10em) * 1.69)`,
  //   height: `calc(100vh - 10em)`,
  //   transform: "translate(-50%, -50%)",
  // };

  const handleScroll = () => {
    // @ts-ignore
    const scrollTop = document.documentElement.scrollTop;
    // const maxScrollTop =
    //   document.documentElement.scrollHeight - window.innerHeight;
    // const scrollFraction = scrollTop / maxScrollTop;
    const frameIndex = Math.max(
      Math.min(NUM_PICTURES - 2, Math.floor((scrollTop / 3000) * NUM_PICTURES)),
      1
    );
    requestAnimationFrame(() =>
      context.current?.drawImage(images[frameIndex + 1], 0, 0)
    );
    if (scrollTop >= 2700 && scrollTop <= 3100) {
      setTop(Math.max(Math.min(50, (2900 - scrollTop) / 2), -60));
    }
    if (scrollTop < 2700 && top !== 50) setTop(50);
    if (scrollTop > 3100 && top !== -60) setTop(-60);

    if (scrollTop > 4000) {
      setIndicator(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [top]);

  return (
    <>
      {indicator && (
        <div className="mouse_scroll">
          <div className="mouse">
            <div className="wheel"></div>
          </div>
          <div>
            <span className="m_scroll_arrows unu"></span>
            <span className="m_scroll_arrows doi"></span>
            <span className="m_scroll_arrows trei"></span>
          </div>
        </div>
      )}
      <canvas
        ref={videoRef}
        style={{
          position: "absolute",
          zIndex: -100,
          top: `calc(${top}% + 2.5em)`,
          left: "50%",
          backgroundSize: "cover",
          width: width >= 1500 ? `100%` : `calc((100vh - 5em) * 1.77)`,
          height: `calc(100vh - 5em)`,
          transform: "translate(-50%, -50%)",
        }}
      />
    </>
  );
};

export default Animation;
