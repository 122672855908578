import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Container from "./Container";
import CustomText from "./CustomText";
import CustomButton from "./CustomButton";

const yc = require("../assets/yc.png");
const qr = require("../assets/orca_qr.png");

//   const interpolate = (
//     start: number,
//     end: number,
//     posStart: number,
//     posEnd: number
//   ) => {
//     return ((pos - posStart) / (posEnd - posStart)) * (end - start) + start;
//   };
const Parallax = () => {
  const [top, setTop] = useState(100);
  const [secondTop, setSecondTop] = useState(100);
  const [showPods, setShowPods] = useState(false);
  const handleScroll = () => {
    // @ts-ignore
    const scrollTop = document.documentElement.scrollTop;
    // const maxScrollTop =
    //   document.documentElement.scrollHeight - window.innerHeight;
    // const scrollFraction = scrollTop / maxScrollTop;
    if (scrollTop >= 2700 && scrollTop <= 3100) {
      setTop(Math.max(Math.min(100, (2980 - scrollTop) / 3), 0));
    }
    if (scrollTop < 2700 && top !== 100) setTop(100);
    if (scrollTop > 3100 && top !== 0) setTop(0);
    if (scrollTop >= 3500 && scrollTop <= 3900) {
      setSecondTop(Math.max(Math.min(100, (3700 - scrollTop) / 2), 30));
    }
    if (scrollTop < 3500 && secondTop !== 100) setSecondTop(100);
    if (scrollTop > 3900 && secondTop !== 30) setSecondTop(30);

    if ((scrollTop < 3700 || scrollTop > 4300) && showPods) {
      setShowPods(false);
    } else if (scrollTop > 3700 && scrollTop < 4300 && !showPods) {
      setShowPods(true);
    }
    if (scrollTop >= 4000 && scrollTop <= 4900) {
      setSecondTop(
        Math.max(Math.min(30, 30 - ((scrollTop - 4000) / 900) * 60), -30)
      );
    }
    // if (scrollTop < 4000 && secondTop !== 30) setSecondTop(30);
    // if (scrollTop > 4900 && secondTop !== -30) setSecondTop(-30);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [top, secondTop, showPods]);

  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          background: "url(" + process.env.PUBLIC_URL + "/background.jpeg)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "relative",
          zIndex: -200,
          marginTop: top + "vh",
        }}
      ></div>
      <div>
        <img
          alt="50% cashback on gongcha"
          style={{
            position: "absolute",
            top: 70 - top + (100 - secondTop) / 1.4 + "vh",
            height: "60vh",
            left: "50%",
            transform: "translate(-40%, -50%)",
            zIndex: -200,
          }}
          src={`${process.env.PUBLIC_URL}/perksMockup.png`}
        />
        {showPods && (
          <motion.img
            alt="splitting with friends"
            animate={{ top: "65vh" }}
            style={{
              position: "absolute",
              top: 100 + "vh",
              height: "50vh",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: -200,
            }}
            src={`${process.env.PUBLIC_URL}/split.png`}
          />
        )}
      </div>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          background: "url(" + process.env.PUBLIC_URL + "/orcaPod.png)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "absolute",
          top: secondTop + "vh",
        }}
      ></div>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          background: "url(" + process.env.PUBLIC_URL + "/flowers.png)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "absolute",
          top: secondTop * 4 - 70 + "vh",
        }}
      ></div>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgb(14, 29, 52)",
          position: "absolute",
          top: secondTop * 4 + 30 + "vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0em 3em",
        }}
      >
        <CustomText
          value={`Scan below to download Orca`}
          style={{ marginBottom: "1em" }}
          color={"#FFF"}
          size={1.5}
          block={true}
          center={true}
          bold={true}
          children={null}
          font={null}
        />
        <img src={qr} style={{ height: "15%", marginBottom: "1em" }} />
        <Container
          style={{
            // backgroundColor: "#171616",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
              maxWidth: 900,
            }}
          >
            <CustomText
              value={`Not a UT student?`}
              style={{ marginBottom: "1em" }}
              color={"#FFF"}
              size={1.5}
              block={true}
              center={true}
              bold={true}
              children={null}
              font={null}
            />
            <CustomText
              value={`Orca is a peer-to-peer payment platform with insane cashback options on local restaurants. We are currently only available on UT Austin campus. Interested in joining? Join our waitlist and we'll let you know when Orca is available for your university!`}
              style={{ marginBottom: "1em" }}
              color={"#FFF"}
              block={true}
              center={true}
              bold={null}
              font={null}
              children={null}
              size={null}
            />
            <CustomButton
              style={{
                backgroundColor: "#FFF",
                borderRadius: "1em",
                padding: "0.5em 2em",
                marginTop: "1em",
              }}
              textStyle={{ textDecoration: "none" }}
              href="https://airtable.com/shrisFRRPUfdeotiA"
              onPress={null}
            >
              <CustomText
                value={`Join Waitlist`}
                color={"#000"}
                block={true}
                center={true}
                bold={true}
                font={null}
                children={null}
                size={null}
                style={null}
              />
            </CustomButton>
          </div>
        </Container>
        <Container style={{ padding: "1em 5em 0em 5em" }}>
          <CustomText
            value={"© 2022 Orca Group Inc. All Rights Reserved."}
            color={"rgba(255, 255, 255, 0.3)"}
            size={0.8}
            block={true}
            center={true}
            bold={true}
            font={null}
            children={null}
            style={null}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <a href="privacy.html" style={{ margin: "0em 1em" }}>
              <CustomText
                value={"Privacy Policy"}
                color={"rgba(255, 255, 255, 0.3)"}
                size={0.8}
                block={null}
                center={null}
                bold={null}
                font={null}
                children={null}
                style={null}
              />
            </a>
            <a href="terms.html" style={{ margin: "0em 1em" }}>
              <CustomText
                value={"Terms & Conditions"}
                color={"rgba(255, 255, 255, 0.3)"}
                size={0.8}
                block={null}
                center={null}
                bold={null}
                font={null}
                children={null}
                style={null}
              />
            </a>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Parallax;
