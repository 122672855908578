import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import "../css/Texts.css";

const Texts = () => {
  const [showTitle, setShowTitle] = useState(true);
  const [showPerks, setShowPerks] = useState(false);
  const [showPods, setShowPods] = useState(false);
  const handleScroll = () => {
    // @ts-ignore
    const scrollTop = document.documentElement.scrollTop;
    if (scrollTop === 0 && !showTitle) {
      setShowTitle(true);
    } else if (scrollTop > 0 && showTitle) {
      setShowTitle(false);
    }
    if ((scrollTop < 3000 || scrollTop > 3500) && showPerks) {
      setShowPerks(false);
    } else if (scrollTop > 3000 && scrollTop < 3500 && !showPerks) {
      setShowPerks(true);
    }
    if ((scrollTop < 3700 || scrollTop > 4300) && showPods) {
      setShowPods(false);
    } else if (scrollTop > 3700 && scrollTop < 4300 && !showPods) {
      setShowPods(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [showTitle, showPerks, showPods]);

  return (
    <>
      <AnimatePresence>
        {showTitle && (
          <motion.div
            className="mainTitleContainer"
            animate={{ top: "5.5em", opacity: 1 }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0, top: "2em" }}
          >
            <h1 className="mainTitle">EZ</h1>
            <h1
              style={{
                marginLeft: ".5em",
              }}
              className="mainTitle"
            >
              Money
            </h1>
          </motion.div>
        )}
        {showPerks && (
          <>
            <motion.div
              className="mainTitleContainer"
              animate={{ top: "5.5em", opacity: 1 }}
              transition={{ duration: 0.5 }}
              exit={{ opacity: 0, top: "12em" }}
            >
              <h1 className="mainTitle">Local</h1>
              <h1
                style={{
                  marginLeft: ".5em",
                }}
                className="mainTitle"
              >
                Perks
              </h1>
            </motion.div>
            <motion.div
              className="descriptionContainer"
              animate={{ top: "17em", opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              exit={{ opacity: 0, top: "25em" }}
            >
              <p className="description">
                We’ve partnered with local restaurants and merchants to provide
                you with discounts to all of your local faves, from your go-to
                burrito joint to the fan-favorite boba stand.
              </p>
            </motion.div>
          </>
        )}
        {showPods && (
          <>
            <motion.div
              className="mainTitleContainer"
              animate={{ top: "5.5em", opacity: 1 }}
              transition={{ duration: 0.5 }}
              exit={{ opacity: 0, top: "12em" }}
            >
              <h1 className="mainTitle">Split</h1>
              <h1
                style={{
                  marginLeft: ".5em",
                }}
                className="mainTitle"
              >
                Bills
              </h1>
            </motion.div>
            <motion.div
              className="descriptionContainer"
              animate={{ top: "17em", opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              exit={{ opacity: 0, top: "25em" }}
            >
              <p className="description">
                Send money instantly and without fees. Create groups with your
                friends and charge them for expenses, bills, and payments. Never
                lose track of costs again, and ditch those annoying
                spreadsheets.
              </p>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default Texts;
