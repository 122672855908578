import React from "react";

export default function Container({ children, style }) {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
    },
  };

  return <div style={{ ...styles.container, ...style }}>{children}</div>;
}
