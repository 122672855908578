import React from "react";

export default function CustomButton({
  children,
  href,
  onPress,
  style,
  textStyle,
}) {
  const styles = {
    container: {
      padding: "1em",
    },
  };

  return (
    <a href={href} onClick={onPress} style={{ ...textStyle }}>
      <div style={{ ...styles.container, ...style }}>{children}</div>
    </a>
  );
}
