import React, { useEffect, useState } from "react";
import LineTo from "react-lineto";
import { AnimatePresence, motion } from "framer-motion";

const CardSecurityBubbles = () => {
  const [pos, setPos] = useState(0);
  const handleScroll = () => {
    // @ts-ignore
    const scrollTop = document.documentElement.scrollTop;
    setPos(scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const interpolate = (
    start: number,
    end: number,
    posStart: number,
    posEnd: number
  ) => {
    return ((pos - posStart) / (posEnd - posStart)) * (end - start) + start;
  };

  return (
    <>
      {pos > 200 && pos < 2600 && (
        <div
          style={{
            position: "absolute",
            width: "100vw",
            maxWidth: "80em",
            height: "100vh",
            display: "flex",
            padding: "0 5em",
          }}
        >
          <AnimatePresence>
            {pos > 200 && pos < 700 && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{
                  zIndex: 100,
                  alignSelf: "flex-end",
                }}
              >
                <div className="floatingBubble">
                  <h2>Freeze your card anytime </h2>
                  <p>
                    Wallet lost or stolen? No problem. Freeze your cards anytime
                    with the click of a button right through our app. Found it?
                    Unfreeze with the click of another.
                  </p>
                </div>
                <div
                  className="dot"
                  style={{
                    top: interpolate(51, 57, 200, 935) + "%",
                    left: "50%",
                    transform: `translateX(-${interpolate(
                      10,
                      28,
                      200,
                      935
                    )}vh)`,
                  }}
                ></div>
                <LineTo
                  from="dot"
                  to="floatingBubble"
                  className="line"
                  within="container"
                  borderColor="white"
                  borderWidth={2}
                  delay={true}
                />
              </motion.div>
            )}
            {pos > 1100 && pos < 1500 && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{ zIndex: 100, alignSelf: "center" }}
              >
                <div
                  className="floatingBubble"
                  style={{
                    position: "absolute",
                    left: "50%",
                    transform:
                      "translate(calc(-50% + " +
                      interpolate(-50, 50, 1100, 1500) +
                      "em), -50%)",
                  }}
                >
                  <h2>End-to-end encrypted</h2>
                  <p>
                    Don't worry, we never store sensitive information like your
                    social security. After all, we are bank-grade.
                  </p>
                </div>
              </motion.div>
            )}
            {pos > 1500 && pos < 1900 && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{ zIndex: 100, alignSelf: "flex-end" }}
              >
                <div className="floatingBubble">
                  <h2>FDIC insured up to $250,000</h2>
                  <p>
                    All your money is backed by FDIC insurance so that you can
                    sleep easy knowing we got your back. Banking services
                    provided by Piermont Bank, Member FDIC.
                  </p>
                </div>
                <div className="dot" style={{ top: "46%", left: "48%" }}></div>
                <LineTo
                  from="dot"
                  to="floatingBubble"
                  className="line"
                  within="container"
                  borderColor="white"
                  borderWidth={2}
                  delay={true}
                />
              </motion.div>
            )}
            {pos > 2100 && pos < 2600 && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{ zIndex: 100, alignSelf: "flex-start" }}
              >
                <div className="floatingBubble">
                  <h2>Spend seamlessly</h2>
                  <p>
                    Spend from a debit card with style, and get access to
                    physical and virtual cards. Get perks for every swipe.
                    Accepted anywhere Mastercard is accepted.{" "}
                  </p>
                </div>
                <div
                  className="dot"
                  style={{
                    top: interpolate(63, 72, 2100, 2700) + "%",
                    left: "48%",
                  }}
                ></div>
                <LineTo
                  from="dot"
                  to="floatingBubble"
                  className="line"
                  within="container"
                  borderColor="white"
                  borderWidth={2}
                  delay={true}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    </>
  );
};

export default CardSecurityBubbles;
